body {
  /*max-width: 96%;*/
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.75);
}

img { margin: 1px; }

.ant-table-tbody tr:nth-child(odd) {background: #ffffff;}
.ant-table-tbody tr:nth-child(even) {background: #eeeeee;}

.ant-btn {
  width: 100%;
}
.ant-row {
  padding-bottom: 2px;
}
.ant-col-xs-24 {
  padding-bottom: 10px;
}
.ant-radio-inner::after {
  border-radius: 4px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-table-thead > tr > th {
  color: white;
  background: #ffa71e;
}
.ant-table-selection-column .ant-table-selection {
    display: none;
    visibility: hidden;
}
.ant-collapse-header {
  text-align: left;
}
.ant-switch, .ant-switch::before, .ant-switch::after {
  border-radius: 0;
}

.domanda, .domanda span {
  font-size: 1.2rem;
}

.custom-filter-dropdown {
  background-color: white;
  border: 1px solid #ffa71e;
}

.custom-filter-dropdown .ant-btn {
  width: 50%;
}

.rdw-link-modal {
  height: 240px;
}
.rdw-link-modal-target-option {
  font-size: 0.8rem;
}

.App {
  text-align: center;
}
.App-logo {
  height: 75px;
}
.App-title {
  font-size: 0.8em;
  margin: 15px;
}

.cursore {cursor: pointer;}

.left {
  text-align: left;
}

.footer {
  font-size: 0.9rem;
  text-align: center;
  position: fixed;
  display: table;
  bottom: 0px;
  /*height: 50px;*/
  width: 100%;
  z-index: 999;
  /*max-width: 96%;*/
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ffa71e;
}

.layout {
  /*border: 1px solid #edecee;*/
}

.header {
  height: 100px;
  background-color: #ffca77;
  padding: 10px;
}
.content {
  /*margin: 10px;*/
  margin-bottom: 20px;
}

.panel {
  margin-bottom: 20px;
  border: 1px solid #ffa71e !important;
}

.login-form {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 320px;
}
.login-form-button {
  width: 100%;
}
.logout-button {
  margin-bottom: 10px;
}

.seleziona {
  padding: 20px;
}
.progetto {
  padding: 5px;
  background-color: #ffdca5;
}
.caso {
  padding: 5px;
  background-color:#ffedd2;
}
.sezione {
  padding: 5px;
  background-color: #fff6e8;
}
.conta-scenari {
  color: white;
  background-color: #ffa71e;
  line-height: 32px;
  text-align: center;
}

.chart {
  padding: 10px;
  margin-bottom: 20px;
  background-color: #edecee;
}
.spazia {
  margin-bottom: 10px;
}
.spazia2 {
  margin-bottom: 20px;
}
.contenuto {
  padding: 7px;
}
.html {
  text-align: left;
}
.accordo {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}
.a_progetto, .a_progetto:hover {
  text-decoration: none;
}
.btn_progetto {
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #ededed;
}
.box-editor {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #edecee;
  /*border: 1px solid #ffa71e;*/
}

.html-editor {
    padding: 5px !important;
    margin-bottom: 20px;
    border: 1px solid #edecee !important;
}

#zoom {
  z-index: 666;
}

.zoom-in {
  cursor: zoom-in;
  width: 100%;
}
.zoom-out {
  cursor: zoom-out;
}

@media only screen and (max-width: 320px)
{
  .footer {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 1024px)
{
  body, .footer {
    max-width: 64rem;
  }
}
